<template>
  <div id="round-overall-top" class="p-2">

    <div class="my-4">
      <b-input-group size="md" prepend="ดูหมายเลข" class="mb-2">
        <b-form-input v-model="findNumbers"></b-form-input>
      </b-input-group>

      <b-input-group prepend="เรียงลำดับ" size="md" class="mb-2">
        <b-input-group-prepend>
          <b-button :variant="sortBy==='number'?'info':'outline-info'" @click="sortBy='number';sortOrder='asc'">หมายเลข</b-button>
          <b-button :variant="sortBy==='bill'?'info':'outline-info'" @click="sortBy='bill';sortOrder='desc'">จำนวนโพย</b-button>
          <b-button :variant="sortBy==='amount'?'info':'outline-info'" @click="sortBy='amount';sortOrder='desc'">ยอดทั้งหมด</b-button>
          <b-button :variant="sortBy==='received'?'info':'outline-info'" @click="sortBy='received';sortOrder='desc'">ถือหุ้น</b-button>
        </b-input-group-prepend>
        <b-input-group-prepend is-text>
          เรียงจาก
        </b-input-group-prepend>
        <b-input-group-append>
          <b-button :variant="sortOrder==='asc'?'info':'outline-info'" @click="sortOrder='asc'">น้อย > มาก</b-button>
          <b-button :variant="sortOrder==='desc'?'info':'outline-info'" @click="sortOrder='desc'">มาก > น้อย</b-button>
        </b-input-group-append>
      </b-input-group>

      <b-input-group prepend="จำนวนแถว" size="md" class="mb-2">
        <b-input-group-append>
          <b-button :variant="rowLength===10?'info':'outline-info'" @click="rowLength=10">10</b-button>
          <b-button :variant="rowLength===50?'info':'outline-info'" @click="rowLength=50">50</b-button>
          <b-button :variant="rowLength===100?'info':'outline-info'" @click="rowLength=100">100</b-button>
          <b-button :variant="rowLength===250?'info':'outline-info'" @click="rowLength=250">250</b-button>
          <b-button :variant="rowLength===500?'info':'outline-info'" @click="rowLength=500">500</b-button>
          <b-button :variant="rowLength===750?'info':'outline-info'" @click="rowLength=750">750</b-button>
          <b-button :variant="rowLength===1000?'info':'outline-info'" @click="rowLength=1000">ทั้งหมด</b-button>
        </b-input-group-append>
      </b-input-group>
    </div>

    <table class="table table-info table-sm table-bordered table-hover mb-0">
      <thead>
        <tr>
          <th rowspan="2" width="40" class="border-right border-left border-top">ลำดับ</th>
          <th colspan="4" class="border-right border-left border-top">3 ตัวบน</th>
          <th colspan="4" class="border-right border-top">3 ตัวโต๊ด</th>
          <th colspan="4" class="border-right border-top">2 ตัวบน</th>
          <th colspan="4" class="border-right border-top">วิ่งบน</th>
        </tr>
        <tr>
          <td width="100" class="border-left">หมายเลข</td>
          <td width="80">โพย</td>
          <td width="100">ยอดทั้งหมด</td>
          <td width="100" class="border-right">ถือหุ้น</td>

          <td width="100">หมายเลข</td>
          <td width="80">โพย</td>
          <td width="100">ยอดทั้งหมด</td>
          <td width="100" class="border-right">ถือหุ้น</td>

          <td width="100">หมายเลข</td>
          <td width="80">โพย</td>
          <td width="100">ยอดทั้งหมด</td>
          <td width="100" class="border-right">ถือหุ้น</td>

          <td width="100">หมายเลข</td>
          <td width="80">โพย</td>
          <td width="100">ยอดทั้งหมด</td>
          <td width="100" class="border-right">ถือหุ้น</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, i) in items">
          <td class="text-center text-secondary border-left">{{ i+1 }}</td>
          <td class="text-center text-primary border-left"><span class="badge badge-primary">{{item.threeTops.number}}</span></td>
          <td
            class="text-center"
          >{{item.threeTops.length | amountFormat(0, '-')}}</td>
          <td
            class="text-right"
            :class="[{'alert-primary':item.threeTops.amount>0}]"
          >{{item.threeTops.amount | amountFormat(2, '-')}}</td>
          <td
            class="text-right border-right"
            :class="[{'alert-success':item.threeTops.received>0}]"
          >{{item.threeTops.received | amountFormat(2, '-')}}</td>

          <td class="text-center text-primary border-left"><span class="badge badge-primary">{{item.threeTods.number}}</span></td>
          <td
            class="text-center"
          >{{item.threeTods.length | amountFormat(0, '-')}}</td>
          <td
            class="text-right"
            :class="[{'alert-primary':item.threeTods.amount>0}]"
          >{{item.threeTods.amount | amountFormat(2, '-')}}</td>
          <td
            class="text-right border-right"
            :class="[{'alert-success':item.threeTods.received>0}]"
          >{{item.threeTods.received | amountFormat(2, '-')}}</td>

          <td class="text-center text-primary"><span class="badge badge-primary">{{item.two.number}}</span></td>
          <td
            class="text-center"
          >{{item.two.length | amountFormat(0, '-')}}</td>
          <td
            class="text-right"
            :class="[{'alert-primary':item.two.amount>0}]"
          >{{item.two.amount | amountFormat(2, '-')}}</td>
          <td
            class="text-right border-right"
            :class="[{'alert-success':item.two.received>0}]"
          >{{item.two.received | amountFormat(2, '-')}}</td>

          <td class="text-center text-primary"><span class="badge badge-primary">{{item.one.number}}</span></td>
          <td
            class="text-center"
          >{{item.one.length | amountFormat(0, '-')}}</td>
          <td
            class="text-right"
            :class="[{'alert-primary':item.one.amount>0}]"
          >{{item.one.amount | amountFormat(2, '-')}}</td>
          <td
            class="text-right border-right"
            :class="[{'alert-success':item.one.received>0}]"
          >{{item.one.received | amountFormat(2, '-')}}</td>
        </tr>
        <tr v-if="!items.length && !isLoading">
          <td colspan="17" class="text-center">ไม่มียอดแทง</td>
        </tr>
        <tr v-else-if="isLoading">
          <td colspan="17" class="alert-warning text-center">กรุณารอซักครู่...</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import _ from 'lodash'

export default {
  name: 'RoundOverallTop',
  props: ['data', 'isLoading'],
  data() {
    return {
      rowLength: 50,
      sortBy: 'number',
      sortOrder: 'asc',
      findNumbers: '',
      profit: null,
      profitAmount: 0
    }
  },
  computed: {
    threeNumberItems() {

      const numberTops = []
      const numberTods = []
      for(let i=0; i<=999; i++) {
        const threeNumber = i.toString().padStart(3, '0')

        numberTops.push({
          number: threeNumber,
          length: this.data.threeNumberTop?.[threeNumber]?.length || 0,
          amount: this.data.threeNumberTop?.[threeNumber]?.amount || 0,
          received: this.data.threeNumberTop?.[threeNumber]?.received || 0
        })

        numberTods.push({
          number: threeNumber,
          length: this.data.threeNumberTode?.[threeNumber]?.length || 0,
          amount: this.data.threeNumberTode?.[threeNumber]?.amount || 0,
          received: this.data.threeNumberTode?.[threeNumber]?.received || 0
        })
      }

      const sortTops = _.sortBy(numberTops.filter((item)=>{
        if(this.findNumbers) {
          return `+${item.number}`.endsWith(this.findNumbers.slice(-3))
        }else{
          return true // item.length > 0
        }
      }), (item)=>{
        return {
          number: this.sortOrder==='asc' ? parseInt(item.number) : -parseInt(item.number),
          bill: this.sortOrder==='asc' ? item.length : -item.length,
          amount: this.sortOrder==='asc' ? item.amount : -item.amount,
          received: this.sortOrder==='asc' ? item.received : -item.received
        }[this.sortBy]
      })

      const sortTods = _.sortBy(numberTods.filter((item)=>{
        if(this.findNumbers) {
          return `+${item.number}`.endsWith(this.findNumbers.slice(-3))
        }else{
          return true // item.length > 0
        }
      }), (item)=>{
        return {
          number: this.sortOrder==='asc' ? parseInt(item.number) : -parseInt(item.number),
          bill: this.sortOrder==='asc' ? item.length : -item.length,
          amount: this.sortOrder==='asc' ? item.amount : -item.amount,
          received: this.sortOrder==='asc' ? item.received : -item.received
        }[this.sortBy]
      })

      return {
        tops: sortTops,
        tods: sortTods
      }
    },
    twoNumberItems() {
      const numbers = []
      for(let i=0; i<=99; i++) {
        const twoNumber = i.toString().padStart(2, '0')
        numbers.push({
          number: twoNumber,
          length: this.data.twoNumberTop?.[twoNumber]?.length || 0,
          amount: this.data.twoNumberTop?.[twoNumber]?.amount || 0,
          received: this.data.twoNumberTop?.[twoNumber]?.received || 0
        })
      }

      return _.sortBy(numbers.filter((item)=>{
        if(this.findNumbers) {
          return `+${item.number}`.endsWith(this.findNumbers.slice(-2))
        }else{
          return true // item.length > 0
        }
      }), (item)=>{
        return {
          number: this.sortOrder==='asc' ? parseInt(item.number) : -parseInt(item.number),
          bill: this.sortOrder==='asc' ? item.length : -item.length,
          amount: this.sortOrder==='asc' ? item.amount : -item.amount,
          received: this.sortOrder==='asc' ? item.received : -item.received
        }[this.sortBy]
      })
    },
    oneNumberItems() {
      const numbers = []
      for(let i=0; i<=9; i++) {
        const oneNumber = i.toString()
        numbers.push({
          number: oneNumber,
          length: this.data.runTop?.[oneNumber]?.length || 0,
          amount: this.data.runTop?.[oneNumber]?.amount || 0,
          received: this.data.runTop?.[oneNumber]?.received || 0
        })
      }

      return _.sortBy(numbers.filter((item)=>{
        if(this.findNumbers) {
          return this.findNumbers.split("").includes(item.number)
        }else{
          return true // item.length > 0
        }
      }), (item)=>{
        return {
          number: this.sortOrder==='asc' ? parseInt(item.number) : -parseInt(item.number),
          bill: this.sortOrder==='asc' ? item.length : -item.length,
          amount: this.sortOrder==='asc' ? item.amount : -item.amount,
          received: this.sortOrder==='asc' ? item.received : -item.received
        }[this.sortBy]
      })
    },
    maxLength() {
      return _.maxBy([{
        key: 'threeNumberTop',
        length: this.threeNumberItems.tops?.length || 0
      }, {
        key: 'threeNumberTode',
        length: this.threeNumberItems.tods?.length || 0
      }, {
        key: 'twoNumberTop',
        length: this.twoNumberItems?.length || 0
      }, {
        key: 'runTop',
        length: this.oneNumberItems?.length || 0
      }], (item)=>{
        return item.length
      })
    },
    items() {
      const items = []
      for(let i=0; i<this.maxLength.length; i++) {
        items.push({
          threeTops: this.threeNumberItems.tops[i] || {
            number: '',
            length: 0,
            amount: 0,
            received: 0
          },
          threeTods: this.threeNumberItems.tods[i] || {
            number: '',
            length: 0,
            amount: 0,
            received: 0
          },
          two: this.twoNumberItems[i] || {
            number: '',
            length: 0,
            amount: 0,
            received: 0
          },
          one: this.oneNumberItems[i] || {
            number: '',
            length: 0,
            amount: 0,
            received: 0
          }
        })
      }

      if(!items.length)
        return []

      const chunks = _.chunk(items, this.rowLength)
      return chunks[0]
    }
  }
}
</script>
<style lang="scss" scoped>
#round-overall-top {
  .table {
    font-size: 90%;

    thead {
      tr {
        th, td {
          text-align: center;
          vertical-align: middle;
        }
      }
    }
    tbody {
      background-color: #FFF;
    }

    th, td {
      .badge {
        font-size: 100%;
      }
      &.border-right {
        border-right: 3px solid #86cfda !important;
      }
      &.border-top {
        border-top: 3px solid #86cfda !important;
      }
      &.border-left {
        border-left: 3px solid #86cfda !important;
      }
      &.border-bottom {
        border-bottom: 3px solid #86cfda !important;
      }
    }
  }
}
</style>
